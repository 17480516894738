body.is-editor {
	.body-container {
		overflow: initial;
	}
}

.body-container {
	overflow: hidden;
}


/**
 * Header
 */
.header-container {
	z-index: 500;
	background: $body-bg;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	position: relative;
	
	.header-bar {
		background-color: $primary;
		z-index: 520;
	}

	.header {
		height: $header-content-height;

		.logo {
			img {
				width: auto;
				max-height: 60px;
			}
		}
		
		.contact-data {
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				margin-right: spacer(3);
				color: $primary;
				font-size: px2rem(22);
			}
			a {
				color: $primary;
				@include underline(0,1);
				font-weight: $font-weight-bold;
			}
		}
		
		.branch {
			> .btn {
				height: 100%;
				text-align: left;
			}
		}
	}

	// Basket
	.btn-basket-container {
		background-color: $light;
		display: flex;
		align-items: center;
		color: $secondary;
		height: 100%;
		@include underline(0,0);
		@include transitionCustom;
		.content {
			display: none;
			margin-left: spacer(3);
			@include font(px2rem(14), 1.3);
			span {
				display: block;
			}
		}
		
		.icon {
			position: relative;
			i {
				font-size: px2rem(22);
				line-height: 1;
			}
			.n {
				position: absolute;
				right: 0;
				top: 0;
				border-radius: 100%;
				background-color: $success;
				color: $white;
				text-align: center;
				min-width: 20px;
				height: 20px;
				@include font(px2rem(12), px2rem(20), $font-weight-bold);
				padding: 0 px2rem(2);
				transform: translate(50%, -50%);
				margin: 0;
			}
		}
		
		&:hover {
			background-color: $secondary;
			color: $white;
		}
		
		.btn {
			background-color: $gray-800;
			color: $primary;
			i {
				font-size: px2rem(20);
			}

			&:hover, &:active, &:focus {
				background-color: $white;
			}
		}
	}

	// ** Responsive **/
	@include media-breakpoint-up(lg) {
		.header-bar {
			height: $header-bar-height;
			position: relative;
		}
	}
	@include media-breakpoint-up(sm) {
		.btn-basket-container {
			.content {
				display: block;
			}
			.icon {
				.n {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		> .container {
			max-width: 100% !important;
		}
	}
	@include media-breakpoint-down(xs) {
		.header {
			height: $header-content-height-xs;
			.logo img {
				max-height: ($header-content-height-xs - 2*5);
			}
		}
	}
}

// **
// Main menu
// **
.main-menu {
	display: flex;
	// First level
	> li {
		position: relative;

		a.menu-item {
			@include underline(0, 0);
			@include font(px2rem(16), 1.4);
			@include transitionCustom;
			color: $white;
			position: relative;
			display: inline-block;
			padding: px2rem(6) px2rem(20);

			&:before {
				width: 100%;
				position: absolute;
				height: 0;
				background-color: $white;
				display: block;
				content: "";
				top: 0;
				left: 0;
			}
		}
		
		&.separator {
			background-color: $white;
			height: 4px;
			margin: spacer(1) 0 !important;
		}

		// Dropdown
		&.has-subMenu > a {
			display: flex;
			align-items: center;
			i {
				margin-left: px2rem(8);
				font-size: px2rem(10);
				flex-shrink: 0;
			}
		}

		// Sub menu
		> ul {
			background-color: $white;
			border-bottom: 2px solid $primary;
			a {
				display: block;
				padding: px2rem(10) px2rem(16);
				color: $body-color;
				@include underline(0,0);
				@include font(px2rem(13), 1.5, $font-weight-medium);
				&:hover {
					color: $white;
					background-color: $secondary;
				}
			}
			li + li {
				border-top: 1px solid $border-color;
			}
		}
	}
	

	@include media-breakpoint-up(lg) {
		display: flex !important;

		// Sub menu
		> li {
			a.menu-item {
				height: $header-bar-height;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			a.menu-item--highlighted {
				background-color: $secondary;
				color: $white;
			}
			> ul {
				position: absolute;
				left: 0;
				top: 100%;
				min-width: 220px;
				max-width: 100%;
				box-shadow: $box-shadow-lg;
			}

			#{$no-touch} &:hover > ul {
				display: block;
			}

			// Active
			&.active > a, #{$no-touch} & > a:hover {
				&:before {
					height: 2px;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		display: none;
		box-shadow: $box-shadow-lg;
		&.show, &.collapsing {
			display: block;
		}
		position: absolute;
		top: 100%;
		right: 0;
		background-color: darken($secondary, 5%);
		padding: spacer(2) 0;
		width: 100%;
		max-width: 300px;
		> li {
			margin: 0 !important;

			> a {
				@include font(px2rem(16), 1.5);
				@include fontFamily("base");
				letter-spacing: 0;
				text-transform: none;
				display: block;
				padding: spacer(2) spacer(3);
				color: $white;
			}
		}
	}
}

.menu-user-section {
	width: 100vh;
	max-width: 300px;
}

// **
// Footer
// **
.footer-container {
	color: $white;
	a {
		@include transitionCustom;
	}

	// Menu
	.footer-menu-list {
		@include font($font-size-sm, $line-height-sm);
		li + li {
			margin-top: spacer(2);
		}
		a {
			color: $gray-600;
			&:hover {
				color: $white;
			}
		}
	}

	// Menu social
	.footer-menu-social-list {
		@include font(px2rem(26), 1.5);
		a {
			color: $gray-600;
			@include underline(0,0);
			&:hover {
				color: $white;
			}
		}
	}

	// Title
	.title-collapse {
		display: flex;
		justify-content: space-between;
		align-items: center;
		i {
			flex-shrink: 0;
			margin-left: spacer(2);
			@include font(px2rem(12), 1);
			color: $primary;
		}
	}

	// Footer
	.footer {
		@include font($font-size-sm, $line-height-sm);
		justify-content: space-between;
		align-items: center;
		color: $gray-600;
		a {
			color: currentColor;
			&:hover {
				color: $white;
			}
		}

		.be {
			a {
				@include underline(0, 0);
			}
		}
	}

	// ** Responsive **
	@include media-breakpoint-down(md) {
		.footer-top-row {
			> div {
				order: 2;
			}
			.logo {
				order: 1;
			}
		}
	}
}
