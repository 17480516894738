p {
	margin: 0;
}
b, strong {
	font-weight: $font-weight-bold;
}
ul, ol {
	margin: 0;
	padding: 0;
}
ul li {
	display: block;
	list-style-type: none;
}
.btn-reset {
	background: none;
	border-width: 0;
	outline: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
	color: currentColor;
	outline: none;
	&:after {
		display: none;
	}
	&:focus {
		outline: none;
	}
}

sup {
	font-size: 60%;
}