$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;
$icomoon-hash: "3gpwk9" !default;

$icon-point-2: "\e91f";
$icon-point: "\e920";
$icon-email: "\e900";
$icon-facebook: "\e901";
$icon-phone: "\e902";
$icon-arrow-sm-right: "\e903";
$icon-arrow-sm-left: "\e904";
$icon-arrow-sm-up: "\e905";
$icon-arrow-sm-down: "\e906";
$icon-arrow-up: "\e907";
$icon-arrow-down: "\e908";
$icon-arrow-left: "\e909";
$icon-arrow-right: "\e90a";
$icon-bin: "\e90c";
$icon-bus: "\e90d";
$icon-car: "\e90e";
$icon-card: "\e90f";
$icon-check: "\e910";
$icon-coins: "\e911";
$icon-edit: "\e912";
$icon-grid: "\e913";
$icon-info: "\e914";
$icon-basket: "\e915";
$icon-line: "\e916";
$icon-map_pin: "\e917";
$icon-one: "\e918";
$icon-quotes: "\e919";
$icon-remove: "\e91a";
$icon-tram: "\e91b";
$icon-upload: "\e91c";
$icon-walk: "\e91d";
$icon-zoom: "\e91e";
$icon-menu: "\e90b";

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-hash}');
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-hash}#iefix') format('embedded-opentype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icomoon-hash}') format('truetype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icomoon-hash}') format('woff'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icomoon-hash}##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-point-2 {
	&:before {
		content: $icon-point-2;
	}
}
.icon-point {
	&:before {
		content: $icon-point;
	}
}
.icon-email {
	&:before {
		content: $icon-email;
	}
}
.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}
.icon-phone {
	&:before {
		content: $icon-phone;
	}
}
.icon-arrow-sm-right {
	&:before {
		content: $icon-arrow-sm-right;
	}
}
.icon-arrow-sm-left {
	&:before {
		content: $icon-arrow-sm-left;
	}
}
.icon-arrow-sm-up {
	&:before {
		content: $icon-arrow-sm-up;
	}
}
.icon-arrow-sm-down {
	&:before {
		content: $icon-arrow-sm-down;
	}
}
.icon-arrow-up {
	&:before {
		content: $icon-arrow-up;
	}
}
.icon-arrow-down {
	&:before {
		content: $icon-arrow-down;
	}
}
.icon-arrow-left {
	&:before {
		content: $icon-arrow-left;
	}
}
.icon-arrow-right {
	&:before {
		content: $icon-arrow-right;
	}
}
.icon-bin {
	&:before {
		content: $icon-bin;
	}
}
.icon-bus {
	&:before {
		content: $icon-bus;
	}
}
.icon-car {
	&:before {
		content: $icon-car;
	}
}
.icon-card {
	&:before {
		content: $icon-card;
	}
}
.icon-check {
	&:before {
		content: $icon-check;
	}
}
.icon-coins {
	&:before {
		content: $icon-coins;
	}
}
.icon-edit {
	&:before {
		content: $icon-edit;
	}
}
.icon-grid {
	&:before {
		content: $icon-grid;
	}
}
.icon-info {
	&:before {
		content: $icon-info;
	}
}
.icon-basket {
	&:before {
		content: $icon-basket;
	}
}
.icon-line {
	&:before {
		content: $icon-line;
	}
}
.icon-map_pin {
	&:before {
		content: $icon-map_pin;
	}
}
.icon-one {
	&:before {
		content: $icon-one;
	}
}
.icon-quotes {
	&:before {
		content: $icon-quotes;
	}
}
.icon-remove {
	&:before {
		content: $icon-remove;
	}
}
.icon-tram {
	&:before {
		content: $icon-tram;
	}
}
.icon-upload {
	&:before {
		content: $icon-upload;
	}
}
.icon-walk {
	&:before {
		content: $icon-walk;
	}
}
.icon-zoom {
	&:before {
		content: $icon-zoom;
	}
}
.icon-menu {
	&:before {
		content: $icon-menu;
	}
}
