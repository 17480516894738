.btn {
	font-weight: $btn-font-weight;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.btn-min {
		max-width: 100%;
		min-width: 220px;
		justify-content: space-between;
	}

	&.btn-block {
		display: flex;
	}

	span + i, i + span {
		margin-left: px2rem(16);
	}

	&.btn-sm {
		span + i, i + span {
			margin-left: px2rem(8);
		}
	}

	i[data-size="sm"] {
		font-size: px2rem(9);
	}

	i[data-size="lg"] {
		font-size: px2rem(18);
	}

	&.btn-icon {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: $btn-min-height;
		border-radius: 100% !important;
		&.btn-xl {
			width: $btn-min-height-xl;
		}
		&.btn-lg {
			width: $btn-min-height-lg;
		}

		&.btn-sm {
			width: $btn-min-height-sm;
		}

		i {
			line-height: inherit;
		}
	}
}

.btn-xl {
	@include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-line-height-xl, 4px);
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	letter-spacing: .05em;
}

.input-group .btn {
	border-radius: $input-border-radius;
}

.btn-outline-primary {
	background-color: $primary-alt;
	color: $primary;
	border-color: $primary-alt;
	&:hover {
		background-color: darken($primary-alt, 4%);
		border-color: darken($primary-alt, 4%);
		color: $primary;
	}
}

.btn-light {
	box-shadow: none;
	i {
		color: $warning;
	}
}

.btn-outline-light {
	color: $gray-600;
	border-color: $gray-600;
	i {
		color: $primary;
	}
}

.btn-white {
	color: $primary;
}

.btn-link {
	color: $gray-600;
	@include underline(0,0);
	padding: 0 !important;
	&:hover {
		color: $primary;
	}
}

.btn-editor {
	$size: 30px;
	background-color: rgba($black, .8);
	border-width: 0;
	height: $size;
	min-width: $size;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font(px2rem(12), 1);
	padding: 0;
	border-radius: 4px;
	margin: 0 !important;
	box-shadow: none !important;
	&.is-danger {
		background-color: $primary;
	}


	&:hover {
		background-color: $black;
		color: $primary-alt;
	}
	&:focus {
		background-color: $black;
	}
}


.btn-editor-image {
	$size: 36px;
	background-color: rgba($black, .8);
	border-width: 0;
	height: $size;
	min-width: $size;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font(px2rem(15), 1);
	padding: 0;
	border-radius: 50%;
	margin: 0 !important;
	box-shadow: none !important;

	&:hover {
		background-color: $primary-alt;
		color: $primary;
	}
	&:focus {
		background-color: $primary-alt;
		color: $primary;
	}
}

.btn-shake-animation {
	position: relative;
	animation-name: btnShakeAnimation;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
	i {
		animation-name: btnShakeAnimationIcon;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in;
	}
}

.btn-shake-animation-vertical {
	position: relative;
	i {
		animation-name: btnAnimationIconVertical;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in;
	}
}

@keyframes btnShakeAnimation {
	0% {
		left: 0
	}
	1% {
		left: -3px
	}
	2% {
		left: 5px
	}
	3% {
		left: -8px
	}
	4% {
		left: 8px
	}
	5% {
		left: -5px
	}
	6% {
		left: 3px
	}
	7% {
		left: 0
	}
}

@keyframes btnShakeAnimationIcon {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(4px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes btnAnimationIconVertical {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(4px);
	}
	100% {
		transform: translateY(0);
	}
}
