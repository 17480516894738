@import "../../../../../../react/node_modules/react-toastify/dist/ReactToastify";

:root {
	--toastify-color-error: #ed1c24;
	--toastify-toast-width: 400px;
}

.Toastify__toast-body {
	font-size: px2rem(14);
}
