/**
 * Contact data
 */
.component__contact-data {
	display: flex;
	align-items: center;
	color: currentColor;

	> i {
		align-self: flex-start;
		flex-shrink: 0;
		margin-right: spacer(3);
		@include font(px2rem(50), 1);
		color: $primary;
	}

	> div {
		flex-grow: 1;

		a {
			color: currentColor;
			@include font($font-size-xl, 1.3, $font-weight-bold);
			@include transitionCustom;
			@include underline(0, 1);
		}
	}

	ul {
		li {
			display: flex;
			align-items: center;

			+ li {
				margin-top: spacer(2);
			}

			> i {
				align-self: start;
				flex-shrink: 0;
				margin-right: spacer(3);
				border: 2px solid $secondary;
				width: 46px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(px2rem(18), 1);
				background: $body-bg;
			}

			> div {
				flex-grow: 1;

				table {
					th + td, td + td {
						padding-left: spacer(2);
					}
				}
			}
		}
	}
}

/**
 * Image gallery / list
 */
.component__gallery {
	.image-zoom {
		img, &:before {
			border-radius: $border-radius;
		}
	}

	@include media-breakpoint-down(xs) {
		@include maxWidth(320px);
	}
}


/**
 * Service list - grid
 */
.component__service-list-grid {
	a {
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		@include underline(0, 0);
		border: 2px solid $border-color;
		border-radius: $border-radius;
		color: $body-color;
		text-align: center;
		@include transitionCustom;

		.image {
			flex-shrink: 0;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.name {
			@include transitionCustom;
		}

		#{$no-touch} &:hover {
			border-color: $primary;
			box-shadow: $box-shadow;

			.name {
				color: $primary;
			}
		}
	}

	// No touch device
	@include media-breakpoint-up(md) {
		#{$no-touch} & a {
			position: relative;
			overflow: hidden;

			.text {
				position: absolute;
				margin: 0 !important;
				left: 0;
				top: 0;
				right: 0;
				display: block;
				padding: spacer(3) spacer(4);
				background-color: $white;
				transform: translateY(-100%);
				opacity: 0;
				@include transitionCustom;
			}

			&:hover .text {
				transform: none;
				opacity: 1;
				box-shadow: $box-shadow;
			}
		}
	}
}


/**
 * Service list (menu)
 */
.component__service-list-menu {
	li:nth-child(1) a {
		border-top: 1px solid $border-color;
	}

	a {
		height: 100%;
		display: flex;
		align-items: center;
		padding: spacer(3) spacer(3);
		@include underline(0, 0);
		border-bottom: 1px solid $border-color;
		color: $body-color;
		min-height: 64px;
		@include transitionCustom;

		.name {
			flex-grow: 1;
			@include transitionCustom;
		}

		i {
			flex-shrink: 0;
			@include transitionCustom;
			color: $gray-500;
			font-size: smaller;
		}

		&:hover {
			border-color: $primary;

			.name {
				color: $primary;
			}

			i {
				color: $primary;
				transform: translateX(3px);
			}
		}

		&.active {
			border-bottom-width: 2px;
			border-color: $primary;

			.name {
				font-weight: $font-weight-bold;
			}

			i {
				color: $body-color;
			}
		}
	}
}

/**
 * Stepper
 */
.component__stepper {
	p {
		margin-left: spacer(4);
	}

	.n {
		@include fontFamily("extra");
		@include font(px2rem(48), 1);
		font-style: italic;
		position: relative;
		color: $primary;

		&:before {
			border-radius: 100%;
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(-50%, -40%);
			display: block;
			background-color: $primary-alt;
			width: 50px;
			height: 50px;
			pointer-events: none;
			z-index: -1;
		}
	}

	.text {
		margin-top: px2rem(12);
	}

	@include media-breakpoint-down(md) {
		.text br {
			display: none;
		}
	}
}

/**
 * Stepper (small)
 */
.component__stepper-sm {
	$size: 36px;
	$indent: px2rem(10);

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: stretch;
		counter-reset: stepperSm;
		@include font(px2rem(15), 1.4);

		li {
			flex: 1;
			padding-right: $indent;

			&.active .step {
				font-weight: $font-weight-bold;

				&:after {
					background-color: $primary;
				}
			}
		}

		.step {
			display: block;
			position: relative;
			padding-left: $size;

			&:before {
				left: divide($size, 2);
				top: divide($size, 2);
				right: 0;
				height: 1px;
				background-color: $gray-200;
				display: block;
				position: absolute;
				pointer-events: none;
				content: "";
			}

			&:after {
				width: $size;
				height: $size;
				border-radius: 100%;
				background-color: $gray-600;
				content: counter(stepperSm);
				counter-increment: stepperSm;
				color: $white;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 0;
				top: 0;
			}

			> span {
				position: relative;
				background-color: $body-bg;
				display: inline-flex;
				min-height: $size;
				justify-content: center;
				flex-direction: column;
				align-items: self-start;
				padding: px2rem(4) $indent;
			}
		}

		a.step {
			color: $body-color;

			&:after {
				background-color: $gray-700;
			}

			> span {
				@include underline(0, 1);
			}

			&:hover {
				&:after {
					background-color: $tertiary;
				}
			}
		}
	}
}

/**
 * Order card
 */
.component__order-card {
	background-color: $white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
	border-radius: $border-radius;

	// Header
	.order-card-header {
		padding: $order-card-padding-y $order-card-padding-x 0;
		display: flex;
		align-items: center;

		> span {
			width: $order-card-header-n-size;
			height: $order-card-header-n-size;
			flex-shrink: 0;
			background-color: $primary-alt;
			border-radius: 100%;
			color: $primary;
			@include fontFamily("lobster");
			@include font(px2rem(24), 1);
			font-style: italic;
			padding-bottom: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: $order-card-header-n-margin;
		}

		.title {
			@include font($font-size-lg, $line-height-lg);
			color: currentColor;
		}
	}

	// Footer
	.order-card-footer {
		padding: 0 $order-card-padding-x $order-card-padding-y;
	}

	// Content
	.order-card-content {
		padding: $order-card-padding-y $order-card-padding-x ($order-card-padding-y * 1.5);
	}

	// Theme: danger
	&.is-danger {
		background-color: $danger;
		color: $white;
	}

	@include media-breakpoint-up(xl) {
		.order-card-content {
			margin-left: ($order-card-header-n-size + $order-card-header-n-margin);
		}
	}
}

/**
 * Base table
 */
.component__table {
	tbody {
		> tr {
			td, th {
				height: 72px;
			}
		}
	}
}

/**
 * Delivery method table
 */
.component__table-delivery {
	tbody {
		label.name {
			margin: 0;
			display: block;
		}
	}
}

/**
 * File list
 */
.component__file-list {
	border-top: 1px solid $border-color;

	a.name {
		height: 100%;
		display: flex;
		align-items: center;
		padding: spacer(2) 0;
		@include underline(0, 0);
		border-bottom: 1px solid $border-color;
		color: $body-color;
		min-height: 42px;
		@include transitionCustom;
		@include font($font-size-sm, 1.3);

		> span {
			flex-grow: 1;
			@include transitionCustom;
			word-break: break-all;
		}

		> i {
			flex-shrink: 0;
			@include transitionCustom;
			color: $gray-500;
			font-size: smaller;
			margin-left: spacer(2);
		}

		&:hover {
			border-color: $primary;

			.name {
				color: $primary;
			}

			i {
				color: $primary;
				transform: translateX(3px);
			}
		}

		&.active {
			border-bottom-width: 2px;
			border-color: $primary;

			.name {
				font-weight: $font-weight-bold;
			}

			i {
				color: $body-color;
			}
		}
	}
}

/**
 * Calendar slider
 */
.component__calendar-slider {
	.head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.custom-select {
			flex-grow: 1;
			max-width: 274px;
			margin: 0 px2rem(12);
		}
	}
}

/**
 * Pictures slider
 */
.component__pictures-slider {
	position: relative;

	.text-container {
		background-color: $primary;
		color: $white;
		padding: px2rem(56) px2rem(44);
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		> i {
			@include font(px2rem(28), 1);
			display: block;
		}

		.text {
			font-size: px2rem(32);
		}
	}

	@include media-breakpoint-up(xl) {
		.text-container {
			min-height: 490px;
		}
	}

	@include media-breakpoint-up(lg) {
		.text-container {
			min-height: 420px;
			border: 18px solid $white;
		}
		.slider {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	@include media-breakpoint-down(lg) {
		.text-container .text {
			font-size: px2rem(24);
		}
	}
	@include media-breakpoint-down(md) {
		.text-container {
			padding: px2rem(40) px2rem(32);

			> i {
				font-size: px2rem(20);
			}
		}
	}
}

/**
 * Paper size grid
 */
.component__paperSizeGrid {
	.image-container {
		margin-bottom: spacer(5);

		.image {
			position: relative;
			display: inline-block;
		}

		.label {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			@include font($font-size-sm, 1);
		}

		img {
			box-shadow: 16px 16px 20px rgba(0, 0, 0, 0.05);
		}

		.sticker {
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			min-width: 60px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			text-transform: uppercase;
			@include font($font-size-sm, $line-height-sm);
			transform: translate(-50%, -50%);
		}
	}

	.price {
		margin-bottom: spacer(2);

		.value {
			font-weight: $font-weight-medium;
			font-size: 24px;
		}
	}

	.sale {
		display: inline-block;
		opacity: .4;
		margin-bottom: spacer(1);
		text-decoration: line-through;
		font-size: $font-size-sm;

		.value {
			font-size: 16px;
		}
	}
}


.component__editor-template {
	position: relative;

	.img-template {
		position: relative;
		z-index: 100;
		pointer-events: none;
	}

	&.is-picture {
		border: 8px solid currentColor;
		border-radius: 4px;

		> .component__cropper {
			left: 0;
			top: 0;
			right: 0;
		}
	}

	@include media-breakpoint-down(xs) {
		&.is-picture {
			border-width: 4px;
			border-radius: 2px;
		}
	}
}

/**
 * Cropper
 */
.component__cropper {
	$controlsIndent: px2rem(10);
	position: absolute;

	> .controls {
		right: $controlsIndent;
		bottom: $controlsIndent;
		position: absolute;
		display: flex;
		align-items: center;

		> i {
			margin-right: spacer(2);
			background: $white;
			border-radius: 50%;
			box-shadow: 0 2px 4px rgba($black, .2);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 22px;
			height: 22px;
			padding: 0 0 1px 1px;
		}
	}

	&[data-controls="left-top"] > .controls {
		left: $controlsIndent;
		top: $controlsIndent;
		right: auto;
		bottom: auto;
	}

	&[data-controls="right-top"] > .controls {
		top: $controlsIndent;
		bottom: auto;
	}

	&[data-controls="left-bottom"] > .controls {
		left: $controlsIndent;
		right: auto;
	}

	.dropzone {
		background-color: $gray-300;
		position: relative;
		z-index: 50;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $gray-600;
		text-align: center;
		padding: 10%;

		* {
			pointer-events: none;
		}

		@include font(px2rem(12), 1.3);
		@include transitionCustom;

		&:before {
			left: 12px;
			right: 12px;
			bottom: 12px;
			top: 12px;
			border-radius: 8px;
			border: 3px dashed $gray-500;
			position: absolute;
			display: block;
			content: "";
			@include transitionCustom;
			pointer-events: none;
		}

		&:hover {
			cursor: pointer;

			&:before {
				border-color: $gray-900;
			}
		}

		&.is-active {
			background-color: $primary-alt;
			color: $primary;

			&:before {
				border-color: $primary;
			}
		}

		&[data-size="sm"] {
			&:before {
				left: 4px;
				right: 4px;
				bottom: 4px;
				top: 4px;
				border-width: 2px;
				border-radius: 0;
			}
		}
	}

	// Transparent mode
	&.component__cropper--transparent {
		.cropper-bg {
			background: transparent;
			position: relative;

			&:before {
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				display: block;
				position: absolute;
				content: "";
				pointer-events: none;
				border: 2px dashed $primary;
			}
		}

		.cropper-modal {
			background: transparent;
		}

		.dropzone {
			background-color: rgba($gray-300, .9);
		}
	}

	@include media-breakpoint-down(md) {
		.dropzone {
			@include font(px2rem(11), 1.5);

			&:before {
				left: 4px;
				right: 4px;
				bottom: 4px;
				top: 4px;
				border-width: 2px;
				border-radius: 0;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.dropzone {
			@include font(px2rem(10), 1.5);
			display: flex;
			align-items: center;
			flex-direction: row;

			.ico {
				@include font(px2rem(12), 1);
				flex-shrink: 0;
			}

			.txt {
				margin-left: 4px;
			}
		}

		> .controls[data-variant="place"] {
			top: 100%;
			bottom: auto;
			left: 50%;
			right: auto;
			transform: translate(-50%, 8px);
		}
	}
}


/**
 * Editor images horizontal container
 */
.component__editorImages-horizontalContainer {
	position: sticky;
	bottom: 0;
	z-index: 200;
	border-radius: 0;
}


/**
 * Editor images
 */
.component__editorImages {
	background-color: $gray-100;
	border-radius: $border-radius;
	display: flex;
	flex-direction: column;
	position: relative;

	.head .upload label {
		margin: 0;
	}

	@mixin vertical {
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;

		.list-container {
			overflow-y: auto;
		}
		.list {
			margin-left: -(px2rem(6));
			margin-right: -(px2rem(6));

			.component__editorImage {
				margin-left: px2rem(6);
				margin-right: px2rem(6);
			}
		}

		.component__editorImage + .component__editorImage {
			margin-top: spacer(2);
		}
	}

	@mixin horizontal {
		.list-container {
			overflow-x: auto;
			overflow-y: hidden;
			min-height: px2rem(90 + 2*6);
		}
		.list {
			//margin-top: -(px2rem(6));
			//margin-bottom: -(px2rem(6));
			width: max-content;

			.component__editorImage {
				//margin-top: px2rem(6);
				//margin-bottom: px2rem(6);
				width: px2rem(160);
				height: px2rem(90);
				min-height: 0;
				padding: 0;
				display: inline-block;
			}
		}

		.component__editorImage + .component__editorImage {
			margin-left: px2rem(4);
		}
	}

	.list-container {
		flex-grow: 1;
		flex-basis: 0;
		position: relative;
		margin-right: -(px2rem(6));
		padding-right: px2rem(6);

		&.os-host-overflow-y {
			margin-right: -(px2rem(8));
			padding-right: px2rem(12);
		}

		&.os-host-overflow-x {
			margin-bottom: px2rem(8);
			padding-bottom: px2rem(8);
		}

		.list {
			position: relative;
		}
	}

	// dropzone
	.dropzone {
		display: none;
	}

	&.is-active {
		* {
			pointer-events: none;
		}

		.dropzone {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			border-radius: inherit;
			z-index: 50;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 10%;
			background-color: $primary-alt;
			color: $primary;
			pointer-events: none;

			@include font(px2rem(13), 1.5);
			@include transitionCustom;

			&:before {
				left: 12px;
				right: 12px;
				bottom: 12px;
				top: 12px;
				border-radius: 8px;
				border: 3px dashed $primary;
				position: absolute;
				display: block;
				content: "";
				@include transitionCustom;
				pointer-events: none;
			}
		}
	}

	// Vertical
	&.is-vertical {
		@include media-breakpoint-up(md) {
			@include vertical;
		}
		@include media-breakpoint-down(sm) {
			@include horizontal;
		}
	}

	// Vertical
	&.is-horizontal {
		@include horizontal;
		@include media-breakpoint-up(md) {
			.head {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p + p {
					margin-left: spacer(2);
				}

				.upload {
					max-width: 300px;
					flex-grow: 1;
					flex-shrink: 0;
				}

				.note {
					margin-top: 0 !important;
					text-align: right;
				}
			}
		}
	}
}


/**
 * Editor image
 */
.component__editorImage {
	background-color: darken($gray-100, 6%);
	background-size: cover;
	background-position: center center;
	position: relative;
	min-height: 100px;
	border-radius: $border-radius;
	overflow: hidden;
	padding-bottom: 56.25%;
	cursor: move;

	> .btn {
		position: absolute;
		right: px2rem(8);
		bottom: px2rem(8);
	}
}


/**
 * Fullscreen loading component
 */
.component__loading {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
	align-items: center;
	justify-content: center;
	color: $white;
	z-index: 1500;
	opacity: 0;
	@include transitionCustom(opacity);

	&.show {
		opacity: 1;
	}

	&:not(.show) {
		pointer-events: none;
	}
}

.component__service-list-slider {
	.slick-track {
		display: flex;
	}

	.slick-slide {
		float: none;
		height: auto;
		padding: px2rem(6);

		> div {
			height: 100%;
			padding: px2rem(8);
		}
	}

	.slide {
		display: flex !important;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		@include transitionCustom;

		img {
			@include transitionCustom;
		}

		#{$no-touch} &:hover {
			transform: translateY(-2px);

			img {
				box-shadow: 0 0 20px rgba($black, .2);
			}
		}
	}

	.slick-dots {
		margin-top: 1rem;
	}

	@include media-breakpoint-down(xs) {
		@include maxWidth(320px);
	}
}


.component__homepage-priceList-item {
	text-align: center;

	a {
		color: currentColor;
	}

	.icon-primary-box a:hover svg {
		transform: scale(1.1);
	}
}

.component__products-item {
	.content {
		.image {
			position: relative;
			overflow: hidden;

			&:after {
				position: absolute;
				opacity: 0;
				background-color: $black;
				@include transitionCustom(all, 0.8s);
				content: "";
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: block;
			}

			img {
				@include transitionCustom(all, 0.8s);
			}
		}

		#{$no-touch} &:hover {
			.image {
				img {
					transform: scale(1.15) rotate(3deg);
				}

				&:after {
					opacity: .22;
				}
			}
		}
	}
}


.component__select-pages {
	background-color: $gray-100;
	border: 1px solid $gray-300;
	border-radius: $border-radius;
	padding: spacer(2) spacer(3);
	max-height: 220px;
	overflow-y: auto;

	.custom-checkbox .custom-control-label:before {
		background-color: $white;
	}

	.custom-col {
		@include media-breakpoint-up(xl) {
			$value: 100% / 8;
			flex: 0 0 $value;
			max-width: $value;
		}
	}
}


.table--tag {
	tr {
		td {
			background: $gray-100;
			border-left-width: 0;
			border-right-width: 0;
		}

		td:first-child {
			border-left-width: 1px;
		}

		td:last-child {
			border-right-width: 1px;
		}
	}

	.td-name > div {
		display: flex;
		align-items: center;

		> i {
			color: $primary;
			margin-right: px2rem(12);
			flex-shrink: 0;
		}

		> span {
			font-weight: bold;
		}

		> a {
			@include underline(1, 0);
		}
	}
}

.fileTree-draggingSource {
	opacity: 0.3;
}

.fileTree-placeholderContainer {
	position: relative;
}

.fileTree-card {
	max-height: 80vh;
	min-height: 240px;

	.card-body {
		flex-grow: 1;
		overflow: auto;
	}
}


.fileTree-actions {
	align-self: flex-start;

	.btn {
		padding: 0;
		width: px2rem(32);
		height: px2rem(32);
		text-align: center;

		i {
			color: $gray-600;
		}
	}

	.btn-group + .btn, .btn + .btn {
		border-left-color: $gray-400;
		border-width: 1px;
	}
}

.fileTree-list {
	$node-indent: 15px;
	$node-line: 3px;
	$node-line-indent: 2px;
	$node-line-gap: $node-line + $node-line-indent;
	padding-bottom: px2rem(12);

	&.fileTree-root {
		padding-left: $node-line-gap;

		> .fileTree-leaf {
			&:last-child {
				border-bottom-width: 0;
			}
		}
	}

	.fileTree-leaf {
		position: relative;
		padding-top: px2rem(5);
		padding-bottom: px2rem(5);

		&:after {
			left: $node-line-gap;
			right: $node-indent;
			bottom: 0;
			height: 1px;
			pointer-events: none;
			display: block;
			position: absolute;
			content: "";
			background: $gray-200;
			opacity: .65;
		}

		> .fileTree-list {
			position: relative;
			padding-left: px2rem($node-indent);
			margin-left: $node-line-gap;

			&:before {
				left: ($node-indent + $node-line-indent);
				top: 0;
				bottom: 0;
				width: $node-line;
				border-radius: 4px;
				display: block;
				content: "";
				background: $gray-200;
				position: absolute;
			}
		}
	}

	.fileTree-item {
		display: flex;
		padding: px2rem(10) px2rem($node-indent) px2rem(10) px2rem($node-indent);
		position: relative;
		margin-left: $node-line-gap;
		align-items: center;

		&.fileTree-item--empty {
			padding-top: 0;
			padding-left: px2rem($node-indent * 2);
			pointer-events: none;

			p {
				font-size: $font-size-sm;
				font-style: italic;
				color: $danger;
			}
		}

		&.fileTree-item--file {
			> i {
				flex-shrink: 0;
				color: $danger;
				margin-right: px2rem(12);
			}

			.item-name {
				flex-grow: 1;

				.name {
					font-weight: bold;
					font-size: px2rem(14);
				}

				.file-attributes {
					font-size: px2rem(12);
					color: $text-muted;
					margin: 0 !important;
				}
			}
		}

		&.fileTree-item--cover .item-name {
			flex-grow: 1;
			align-self: center;

			.info {
				font-size: 85%;
				color: $text-muted;
			}
		}

		&.fileTree-item--cover .item-toggle {
			position: absolute;
			left: 0;
			transform: translate(-50%, -50%);
			top: 50%;
			font-size: 15px;
			color: $gray-600;
			cursor: pointer;
			background: $white;

			&:hover {
				color: $primary;
			}
		}

		&:hover, &.is-dropTarget {
			background: lighten($warning, 45%) !important;
		}

		&.is-active {
			$active-bg: lighten($primary, 45%);
			background: $active-bg;

			+ .fileTree-list {
				&:before, > .fileTree-leaf > .fileTree-item:after {
					background: $primary;
				}
			}

			&.fileTree-item--cover .item-name {
				.info {
					color: $primary;
				}
			}
		}

		.fileTree-actions {
			align-self: flex-start;
		}
	}

	.fileTree-placeholder {
		background-color: $primary;
		height: 2px;
		position: absolute;
		right: 0;
		transform: translateY(-50%);
		top: 0;
		left: $node-line-indent;

		&:before, &:after {
			top: (0 - 2*$node-line);
			bottom: (0 - 2*$node-line);
			width: $node-line;
			background-color: $primary;
			content: "";
			display: block;
			position: absolute;
		}

		&:before {
			left: 0;
		}

		&:after {
			right: 0;
		}
	}
}

.fileTree-draggingPreview {
	align-items: center;
	background-color: $primary;
	border-radius: $border-radius;
	box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25);
	color: #fff;
	display: inline-block;
	font-size: 14px;
	padding: px2rem(6) px2rem(12);
	pointer-events: none;
}
