.table--file-list {
	overflow-y: auto;
	max-height: min(100vh, 500px);

	thead {
		th {
			padding: 8px 8px;
			vertical-align: middle;
		}
	}

	tbody {
		td {
			padding: 12px 8px;
			vertical-align: middle;
		}

		tr {
			td:first-child {
				padding-left: 12px;
			}

			td:last-child {
				padding-right: 12px;
			}

			&[draggable="true"] {
				cursor: move;
				cursor: -webkit-grab;
				cursor: -moz-grab;
				cursor: grab;

				&:active {
					cursor: -webkit-grabbing;
					cursor: -moz-grabbing;
					cursor: grabbing;
				}
			}
		}
	}

	.td-check {
		padding-right: 4px;
		width: ($custom-control-indicator-size + px2rem(12 + 4));

		.custom-control.custom-checkbox {
			padding-left: $custom-control-indicator-size;

			.custom-control-label {
				&:before, &:after {
					left: -$custom-control-indicator-size;
				}
			}
		}
	}

	.td-name {
		@include font(px2rem(13));
		word-break: break-all;
	}

	.td-remove {
		padding-left: px2rem(4);
		text-align: right;

		i {
			cursor: pointer;
			opacity: .5;
			@include transitionCustom;

			&:hover {
				opacity: 1;
			}
		}
	}

	.td-price {
		font-weight: $font-weight-bold;
		text-align: right;
		white-space: nowrap;
		@include font(px2rem(14), 1.2);
	}

	.file-attributes {
		font-style: italic;
		color: $text-muted;
	}

	// TODO
	/*tr.is-dragging {
		background-color: rgba($white, .5);
		box-shadow: $box-shadow;

		td:not(.td-dragging) {
			opacity: 0;
		}
	}*/
}
