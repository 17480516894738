/**
 * Editor container
 */
.section__editor {
	position: relative;
}

/**
 * Editor header
 */
.section__editor--header {
	background-color: $gray-100;
	padding: spacer(3) 0;
}

/**
 * Editor footer
 */
.section__editor--footer {
	background-color: $primary;
	color: $white;
	padding: px2rem(12) 0;
}


.section__offer-list {
	$shape-height: 68px;

	background-color: $gray-100;
	position: relative;
	z-index: 10;

	> .container {
		padding-top: $shape-height;
	}

	.shape {
		position: absolute;
		left: 0;
		right: 0;
		overflow: hidden;
		pointer-events: none;

		svg {
			display: block;
			width: calc(100% + 1.3px);
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			height: $shape-height;
		}

		&.shape-top {
			top: 0;

			.shape-fill {
				fill: $body-bg;
			}
		}

		&.shape-bottom {
			top: 100%;

			.shape-fill {
				fill: $gray-100;
			}
		}
	}
}


.section__homepage-priceList {
	background: url("../image/copyji_pozadi_opt.jpeg") no-repeat 50% 50%;
	background-size: cover;
	position: relative;

	&:before {
		pointer-events: none;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba($dark, .85);
		display: block;
		content: "";
		position: absolute;
	}

	> .container {
		position: relative;
	}
}

.section__contact-slide {
	height: 650px;
	background: url("../image/bg-jihlava.jpg") no-repeat 50% 50%;
	background-size: cover;
	@include media-breakpoint-down(md) {
		height: 500px;
	}
	@include media-breakpoint-down(sm) {
		height: 300px;
	}
}

.section__contact {
	margin-top: -250px;
	position: relative;
	@include media-breakpoint-down(sm) {
		margin-top: 0;
	}
}

.section__bg-headline {
	color: $white;
	position: relative;
	background-size: cover;
	background-position: 50% 50%;

	&:before {
		pointer-events: none;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba($gray-800, .8);
		display: block;
		content: "";
		position: absolute;
	}

	> .container {
		position: relative;
	}
}

.section__career-headline {
	background-color: $primary;
	background-image: linear-gradient(130deg, $primary 0%, #3d000f 89%);
	@include careerBackground('../image/bg-career.png') {
		opacity: .15;
		background-size: cover;
	}
;;

	.content {
		color: $white;
	}

	.shape {
		transform: rotate(180deg);
		bottom: -1px;
		overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
		line-height: 0;

		svg {
			display: block;
			position: relative;
			left: 50%;
			width: calc(260% + 1.3px);
			height: 120px;
			transform: translateX(-50%) rotateY(180deg);

			path {
				fill: $white;
			}
		}
	}

	.image img {
		border-radius: 300px 100px 100px 100px;
		box-shadow: 0 0 30px 0 rgba($black, .12);
		@include media-breakpoint-down(sm) {
			@include maxWidth(320px);
		}
	}
}

.section__career-about {
	@include careerBackground('../image/bg-career-2.png') {
		background-size: cover;
	}
;

	.content {
		color: $white;
	}

	.image img {
		border-radius: 100px 100px 300px 100px;
		box-shadow: 0 0 30px 0 rgba($black, .12);
		@include media-breakpoint-down(md) {
			@include maxWidth(320px);
		}
	}

	@include media-breakpoint-down(md) {
		&:before {
			background-position: bottom left;
		}
	}
}

.section__career-products {
	@include careerBackground('../image/bg-career-3.png') {
		background-position: bottom right;
	}
;

	.content {
		background-color: $white;
		border-radius: 15px 15px 15px 70px;
		box-shadow: 0 0 20px 0 rgba($black, .05);
		width: 100%;

		.icon {
			img {
				width: 66px;
			}
		}
	}

	.image img {
		border-radius: 100px 100px 300px 100px;
		box-shadow: 0 0 30px 0 rgba($black, .12);
		@include media-breakpoint-down(md) {
			@include maxWidth(320px);
		}
	}
}


.section__career-form {
	background-color: $primary;
	background-image: linear-gradient(130deg, $primary 0%, #3d000f 89%);
	@include careerBackground('../image/bg-career-4.png') {
		opacity: .15;
		background-size: cover;
	}
;

	.shape {
		top: -1px;
		overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
		line-height: 0;

		svg {
			display: block;
			position: relative;
			left: 50%;
			width: calc(230% + 1.3px);
			height: 120px;
			transform: translateX(-50%) rotateY(180deg);

			path {
				fill: $white;
			}
		}
	}

	.image {
		img {
			border-radius: 300px 100px 100px 100px;
			box-shadow: 0 0 30px 0 rgba($black, .12);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.image img {
			transform: translateY(-16%);
		}
	}
}


.section__products-headline {
	background-color: $info-light;
	@include media-breakpoint-up(lg) {
		background: $info-light url('../image/bg-products.png') bottom right no-repeat;
	}

	h1 {
		@include media-breakpoint-up(lg) {
			@include font(64px, 1.2);
		}
	}

	p {
		@include media-breakpoint-up(lg) {
			@include font(24px);
		}
	}
}


.section__product-headline {
	.slider {
		.slide {
			height: 260px;
			position: relative;
			overflow: hidden;

			img {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				min-height: 100%;
				min-width: 100%;
				transform: translate(-50%, -50%);
			}
		}

		&.slick-initialized .slick-slide {
			&.slick-current {
				.slide img {
					animation: zoom-in 4s ease-in-out forwards;
				}
			}
		}
	}
}

@keyframes zoom-in {
	0% {
		transform: translate(-50%, -50%);
	}
	100% {
		transform: translate(-50%, -50%) scale(1.2) rotate(2deg);
	}
}

@mixin slideAnimation($name, $speed, $delay, $defaultValue, $activeValue) {
	transform: $defaultValue;

	.slick-slide.slick-current & {
		animation-name: $name;
		animation-duration: $speed;
		animation-delay: $delay;
		animation-fill-mode: forwards;
	}
	
	@keyframes #{$name} {
		0% {
			transform: $defaultValue;
		}
		100% {
			transform: $activeValue;
		}
	}
}

.section__homepage-slider {
	$speed: 4000ms;
	.slide {
		overflow: hidden;
		background-size: cover;
		.container {
			height: 540px;
		}
	}

	@include media-breakpoint-down(lg) {
		.slide .container {
			height: 480px;
		}
	}

	@include media-breakpoint-down(sm) {
		.slide .container {
			height: 400px;
		}
	}

	@include media-breakpoint-down(xs) {
		.slide .container {
			height: 360px;
		}
	}
}
.section__homepage-slider--slide-1 {
	.container {
		position: relative;
		max-width: 1011px;

		.text {
			@include fontFamily(title);
			text-transform: uppercase;
			@include font(px2rem(60), 1.2, $font-weight-xbold);
			text-shadow: $black 0 0 20px;
			position: absolute;
			left: 50%;
			right: -10%;
			top: 12%;
			z-index: 5;
			color: $white;
			letter-spacing: -0.03em;
		}

		.bottom {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			width: 100%;

			.shelf {
				width: 100%;
				@include slideAnimation(slide1_shelf, 400ms, 0, translateY(100%), translateY(0));
			}

			.cover {
				position: absolute;
				bottom: 60%;
				right: 14%;
				@include slideAnimation(slide1_cover, 500ms, 100ms, translateX(-100vw), translateX(0));
			}

			.hrnek {
				position: absolute;
				bottom: 40%;
				right: 20%;
				@include slideAnimation(slide1_hrnek, 500ms, 400ms, translateX(-100vw), translateX(0));
			}
		}

		.top {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			.bag {
				@include slideAnimation(slide1_bag, 400ms, 400ms, translateY(-100%), translateY(0));
			}
			.tshirt {
				@include slideAnimation(slide1_tshirt, 400ms, 800ms, translateY(-100%), translateY(0));
			}
		}
	}
	
	@include media-breakpoint-up(lg) {
		.container {
			max-width: 1011px;
		}
	}
	
	@include media-breakpoint-down(lg) {
		.container {
			.top {
				.bag {
					width: 180px;
				}
				.tshirt {
					width: 210px;
				}
			}

			.text {
				@include font(px2rem(44), 1.2);
				right: 0;
				top: 12%;
			}
		}
	}
	
	@include media-breakpoint-down(sm) {
		.container {
			.top {
				.bag {
					display: none;
				}
			}
			.bottom {
				.cover {
					width: 220px;
				}
				.hrnek {
					width: 120px;
				}
			}

			.text {
				left: 40%;
				top: 12%;
			}
		}
	}
	
	@include media-breakpoint-down(xs) {
		.container {
			.top {
				.tshirt {
					width: 130px;
				}
			} 
			.bottom {
				.cover {
					width: 180px;
				}
				.hrnek {
					width: 90px;
				}
			}

			.text {
				@include font(px2rem(34), 1.2);
				left: 10%;
				right: 10%;
				top: 12%;
			}
		}
	}
}
.section__homepage-slider--slide-2 {
	background-position: 0 50%;
	.container {
		position: relative;
		max-width: 1011px;
		text-align: right;

		.text-top {
			> span {
				position: relative;
				padding: px2rem(8) px2rem(18) px2rem(6);
				color: transparent;
				text-transform: uppercase;
				@include fontFamily(title);
				@include font(px2rem(40), 1.2, $font-weight-xbold);
				display: inline-block;
				letter-spacing: -0.02em;

				> span {
					position: relative;
					z-index: 5;
				}

				.slick-slide.slick-current & {
					animation-name: slide2_textTop;
					animation-duration: 300ms;
					animation-delay: 1000ms;
					animation-fill-mode: forwards;
				}

				@keyframes slide2_textTop {
					from {
						color: transparent;
					}
					to {
						color: $white;
					}
				}
				
				&:before, &:after {
					display: block;
					width: 0;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					pointer-events: none;
					content: "";
				}
				&:before {
					background-color: $white;

					.slick-slide.slick-current & {
						animation-name: slide2_textTop_bg;
						animation-duration: 300ms;
						animation-fill-mode: forwards;
					}

					@keyframes slide2_textTop_bg {
						from {
							width: 0;
						}
						to {
							width: 100%;
						}
					}
				}
				&:after {
					background-color: $primary;

					.slick-slide.slick-current & {
						animation-name: slide2_textTop_bg2;
						animation-duration: 300ms;
						animation-delay: 500ms;
						animation-fill-mode: forwards;
					}

					@keyframes slide2_textTop_bg2 {
						from {
							width: 0;
						}
						to {
							width: 100%;
						}
					}
				}
			}
		}

		.text-main {
			text-transform: uppercase;
			@include fontFamily(title);
			@include font(px2rem(80), 1.1, $font-weight-xbold);
			letter-spacing: -0.02em;
			color: $black;
		}
		
		.btn-container {
			position: absolute;
			right: spacer(3);
			bottom: spacer(8);
			left: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.container {
			max-width: 1011px;
		}
	}

	@include media-breakpoint-down(lg) {
		.container {
			.text-top {
				> span {
					@include font(px2rem(34), 1.2);
				}
			}

			.text-main {
				@include font(px2rem(68), 1.1);
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.container {
			.text-top {
				> span {
					@include font(px2rem(30), 1.2);
				}
			}

			.text-main {
				@include font(px2rem(52), 1.1);
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.container {
			.text-top {
				> span {
					@include font(px2rem(20), 1.2);
				}
			}

			.text-main {
				@include font(px2rem(30), 1.1);
			}
		}
	}
}

.section__star-map-page {
	color: rgba($white, .8);
	@include fontFamily(montserrat);
	.h1, .h2, .h3, .h4, .h5, .h6 {
		&:not([class^="ff-"]):not([class*=" ff-"]) {
			@include fontFamily(montserrat);
		}
	}
	.title-headline {
		@include font(px2rem(150), 1.1);
		@include media-breakpoint-down(lg) {
			font-size: px2rem(110);
		}
		@include media-breakpoint-down(md) {
			font-size: px2rem(80);
		}
	}
	.title-example {
		@include font(px2rem(110), 1.1);
		@include media-breakpoint-down(md) {
			font-size: px2rem(70);
		}
	}
	.sub-title-example {
		@include font(px2rem(24), 1.4, $font-weight-bold);
	}
	.title-gift {
		@include font(px2rem(60), 1.12);
		@include media-breakpoint-down(md) {
			font-size: 50px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 40px;
		}
	}
	.title-offer {
		@include font(px2rem(50), 1.12);
		@include media-breakpoint-down(md) {
			font-size: 40px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
	}
	.sub-title-offer {
		letter-spacing: .12em;
	}
	.wysiwyg {
		@include font(px2rem(17), 1.9);
	}
	.btn-outline-info {
		padding: spacer(2) spacer(3);
		font-weight: $font-weight-normal;
		letter-spacing: 1px;
		> i + span, > span + i {
			margin-left: spacer(2);
		}
		> span {
			color: $white;
			text-transform: uppercase;
		}
	}
	hr {
		border-top-color: rgba($white, .1);
	}
	
	.component__star-map__header {
		background-color: #28369e;
		background-image: url("../image/star-map/bg.jpeg");
		background-size: cover;
		overflow: hidden;
		position: relative;
		padding: px2rem(150) 0 px2rem(500);
		
		.content {
			position: relative;
			z-index: 5;
			.btn-xl {
				box-shadow: 0 20px 50px 0 rgba(16 ,34 ,40, .5);
			}
		}
		
		.title {
			@include fontFamily(lobster);
			color: $white;
			text-shadow: 0 0 15px #10a3e8;
		}
		
		.subtitle {
			color: $white;
			@include font(px2rem(20), 1.1);
			text-transform: uppercase;
			letter-spacing: .6px;
			text-shadow: 5px 5px 15px rgba($black, 0.3);
		}
		
		.bottom {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 5;
		}
		
		.example {
			width: 100%;
			max-width: 470px;
			bottom: -150px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		
		.earth {
			width: 700px;
			bottom: -80px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		
		.zodi {
			position: absolute;
			&.zodi-6 img {
				opacity: .35;
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: px2rem(80) 0 px2rem(500);
			.btn.btn-xl {
				@include font($btn-font-size, $btn-line-height); 
				padding: $btn-padding-y $btn-padding-x;
			}
			.zodi {
				img {
					opacity: .6;
				}
			}

			.example {
				z-index: 3;
			}
		}
		
		@include media-breakpoint-down(xs) {
			padding: px2rem(40) 0 px2rem(350);

			.example {
				max-width: 300px;
				bottom: -80px;
			}
			.zodi {
				img {
					width: 80px;
					height: auto;
				}
			}
		}
	}
	
	.component__star-map__offer {
		.headline-container {
			color: rgba($white, .3);
			text-transform: uppercase;
			i.ico {
				animation-name: btnAnimationIconVertical;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-out;
				@include font(px2rem(20), 1);
			}
			.content {
				@include font(px2rem(17), 1.3,  $font-weight-medium);
				letter-spacing: .2em;
			}
		}
	}
	
	.component__star-map__example {
		background-color: $tertiary;
		@include gradient-y($tertiary, $tertiary-2);
		
		.box {
			border: 5px dashed rgba($white, .06);
			border-radius: $border-radius-sm;
			padding: px2rem(30);
			display: flex;
			flex-direction: column;
			align-items: center;
			i {
				background-color: $info;
				@include font(px2rem(40));
				width: 80px;
				height: 80px;
				text-align: center;
				color: $white;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
			}
			.text {
				@include font(px2rem(14), 1.4);
			}
			
			&.highlighted {
				border-color: $info;
				border-radius: $border-radius-lg;
				border-style: solid;
				box-shadow: 0 0 30px 0 rgba($info, .3);
				animation: pulsate-bck 2s ease-in-out infinite both;
				
				.title {
					@include fontFamily(lobster);
					@include font(px2rem(64), 1, $font-weight-normal);
					color: $info;
				}
				.text {
					@include font(px2rem(17), 1.3);
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			.box.highlighted {
				.title {
					font-size: px2rem(40);
				}
			}
		}
	}
	
	.component__star-map__bottom {
		.h1 {
			@include font(px2rem(110), 1);
		}
		@include media-breakpoint-down(md) {
			.h1 {
				font-size: 80px;
			}
		}
		@include media-breakpoint-down(sm) {
			.h1 {
				font-size: 50px;
			}
		}
	}
	
	.component__star-map__colors {
		background-color: $tertiary-2;
		.sub-title {
			letter-spacing: .125em;
			text-transform: uppercase;
		}
		
		.content-container {
			background-color: $tertiary;
			background-image: radial-gradient(at bottom right, $tertiary 0%, #313452 100%);
			border-radius: 30px 0 30px 0;
			border-style: solid;
			border-width: px2rem(10);
			border-color: #313452;
			padding: px2rem(30);
		}
		
		@include media-breakpoint-down(sm) {
			.container {
				padding-left: 0;
				padding-right: 0;
				max-width: 100%;
			}
			.content-container {
				border-left-width: 0;
				border-right-width: 0;
			}
		}
	}

	@keyframes pulsate-bck {
		0% {
			transform: scale(1)
		}

		50% {
			transform: scale(.95)
		}

		100% {
			transform: scale(1)
		}
	}
}


.section__file-editor {
	position: relative;

	.sticky {
		z-index: 10;
	}

	.file-editor-header {
		background-color: darken($gray-100, 2%);
		color: $dark;
		border-radius: $border-radius;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 10;
		position: relative;
	}

	/*.file-editor-item {
		$size: px2rem(36px);
		$indent: px2rem(10);

		position: relative;
		padding-left: ($size + $indent);

		+ .file-editor-item {
			margin-top: spacer(4);
		}

		&:before {
			content: "";
			position: absolute;
			top: ($size / 2);
			left: ($size / 2);
			bottom: 0;
			display: block;
			width: 2px;
			background-color: $gray-200;
			transform: translateX(-50%);
			opacity: .5;
		}

		.file-editor-item--header {
			.n {
				width: $size;
				height: $size;
				border-radius: 100%;
				background-color: $gray-700;
				content: counter(stepperSm);
				counter-increment: stepperSm;
				color: $white;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 0;
				top: 0;
			}

			.title {
				min-height: $size;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: self-start;
				@include font(px2rem(18));
				text-transform: uppercase;
				font-weight: $font-weight-bold;
			}
		}
	}*/
}
