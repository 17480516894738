// **
// Breadcrumbs
// **
.breadcrumb {
	$indent: px2rem(26);
	color: $secondary;

	li {
		position: relative;
		padding-right: $indent;
		margin-top: .125rem;
		margin-bottom: .125rem;

		a {
			@include underline(0,0);
			@include transitionCustom(color);
			color: $gray-600;

			&:hover {
				color: $primary;
			}
		}

		// Divider
		&:before {
			display: none !important;
		}

		&:not(:last-child) {
			&:after {
				pointer-events: none;
				content: $icon-arrow-sm-right;
				position: absolute;
				@include fontFamily("ico");
				top: 50%;
				right: ($indent / 2);
				margin-top: -1px;
				display: block;
				transform: translate(50%, -50%);
				@include font(px2rem(4), 1);
				color: $primary;
			}
		}
	}
}


/**
 * Popover
 */
.popover {
	.popover-body {
		line-height: $popover-line-height;
	}
}

.alert-custom {
	display: flex;
	align-items: center;
	.icon {
		flex-grow: 0;
		margin-right: spacer(2);
	}
	.text {
		font-size: $font-size-base;
	}
}

/**
 * Modal
 */
.modal {
	.modal-header {
		display: block;

		.close {
			position: absolute;
			right: spacer(3);
			top: spacer(3);
			@include font(px2rem(10), 1);
			color: $primary;
			opacity: 1;
			padding: 0;
			margin: 0;

			&:hover {
				color: $body-color;
			}
		}
	}
}

.nav-tabs {
	.nav-link {
		color: $secondary;
	}
}


// **
// Tabs
// **
.nav-tabs-priceList {
	$border-radius-size: 50px;
	
	justify-content: center;
	background-color: $light;
	border-radius: $border-radius-size;
	display: inline-flex;
	padding: spacer(1);
	.nav-link {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: $font-weight-semibold;
		color: $gray-600;
		background-color: transparent;
		border-radius: $border-radius-size;
		&.active {
			color: $white;
			background-color: $blue;
		}
		
		&:not(.active):hover {
			 color: $dark;
		}
	}
}

/**
 * Table
 */
.table {
	&.align-middle {
		td, th {
			vertical-align: middle;
		}
	}
	&:not(.table-bordered) {
		tr:first-child {
			th, td {
				border-top-width: 0;
			}
		}
	}
	&.table-bordered {
		@include font($font-size-md, $line-height-md);
		thead th {
			background-color: $gray-200;
			color: $gray-800;
			text-transform: uppercase;
		}
		td, th {
			vertical-align: middle;
		}
	}
}

.nav-menu {
	li:first-child a {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}
	li:last-child a {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
	a {
		display: block;
		padding: px2rem(12) spacer(3);
		border: 1px solid $gray-300;
		color: $body-color;
		font-weight: $font-weight-semibold;
		&.active {
			background-color: $secondary;
			color: $white;
		}
	}
	li:not(:last-child) a {
		border-bottom-width: 0;
	}
}

/**
 * Nav tabs card
 */
.card-header--tabs {
	padding: 0;
	border-width: 0;
	border-bottom-width: 1px;
	.nav-tabs {
		position: relative;
		margin: (-$card-border-width) (-$card-border-width) 0 (-$card-border-width);
		@include font(px2rem(14));
		.nav-item {
			flex-grow: 1;
			border-radius: 0;
		}
		.nav-link {
			border-top-width: 2px;
			border-radius: 0;
			padding: px2rem(10) px2rem(12);
			&.active {
				border-top-color: $primary;
				font-weight: $font-weight-bold;
				color: $body-color;
			}
		}
	}
}
