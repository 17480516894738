.wysiwyg {
	> *:last-child {
		margin-bottom: 0 !important;
	}

	// ** Titles **
	h2 {
		@include h2;
	}

	h3 {
		@include h3;
	}

	h4 {
		@include h4;
	}

	h5 {
		@include h5;
	}

	h6 {
		@include h6;
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
		box-shadow: $box-shadow;
	}

	a {
		color: $primary;
		@include underline(1, 0);

		&:hover {
			color: $primary;
		}
	}

	// ** List **
	ul {
		margin-left: 30px;

		li {
			padding: 3px 0;
			position: relative;
			padding-left: 16px;

			&:before {
				content: "";
				display: block;
				position: absolute;
				background: $body-color;
				height: 4px;
				width: 4px;
				border-radius: 50%;
				left: 0;
				top: (($line-height-base * $font-size-base) / 2);
				transform: translateY(1px);
			}
		}
	}

	ol {
		padding-inline-start: 40px;
		li {
			padding-top: 3px;
			padding-bottom: 3px;
		}
	}

	// ** General **
	p, ul {
		margin-bottom: .75rem;
	}

	ol, table {
		margin-bottom: 1.25rem;
	}

	h3, h2, h4 {
		margin-bottom: 1rem;
		margin-top: 2rem;

		&:first-child {
			margin-top: 0;
		}
	}

	h5, h6 {
		margin-bottom: .75rem;
	}

	p + ul, p + ol {
		margin-top: 1.25rem;
	}

	// ** Table **
	table {
		margin-bottom: 2rem;

		thead {
			//text-transform: uppercase;
			//@include font($font-size-sm, 1.5, $font-weight-bold);
			//color: $black;
		}
	}

	&.fs-lg {
		p, ul {
			margin-bottom: 1.5rem;
		}

		p + ul, p + ol {
			margin-top: 1.75rem;
		}
	}

	@include media-breakpoint-down(md) {
		//font-size: $font-size-md;
	}
}
