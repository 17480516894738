$fileDropzone-active: $secondary !default;
$fileDropzone-bg: darken($gray-100, 2%) !default;
$fileDropzone-bg-active: darken($gray-100, .5%) !default;
$fileDropzone-border-color: $gray-400 !default;
$fileDropzone-border-color-active: $fileDropzone-active !default;
$fileDropzone-border-width: 2px !default;
$fileDropzone-border-radius: $border-radius !default;
$fileDropzone-padding-x: px2rem(16) !default;
$fileDropzone-padding-y: px2rem(16) !default;

.fileDropzone {
	background-color: $fileDropzone-bg;
	border: $fileDropzone-border-width dashed $fileDropzone-border-color;
	border-radius: $fileDropzone-border-radius;
	padding: $fileDropzone-padding-y $fileDropzone-padding-x;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
	min-height: 100px;
	@include transitionCustom;

	.fileDropzone-input {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;
	}

	.fileDropzone-icon {
		position:       absolute;
		font-size:      px2rem(100);
		transform:      rotate(14deg) translate(10%, 22%);
		left:           0;
		bottom:         0;
		opacity:        0.1;
		pointer-events: none;
	}

	.fileDropzone-placeholder {
		color: $gray-800;
		font-size: px2rem(18);

		.description {
			font-size: px2rem(12);
			font-weight: $font-weight-bold;
			margin: px2rem(4) 0;
			text-transform: uppercase;
		}

		.note {
			opacity: .7;
			font-size: px2rem(12);
		}
	}

	.fileDropzone-value {
		color:       $fileDropzone-active;
		font-size:   px2rem(18);
		font-weight: $font-weight-bold;
	}

	&:hover, &.is-active {
		border-color: $fileDropzone-border-color-active;
		background-color: $fileDropzone-bg-active;
		border-style: solid;

		.fileDropzone-placeholder {
			color: $fileDropzone-active;
		}
	}

	@include media-breakpoint-down(md) {
		.fileDropzone-placeholder {
			font-size: px2rem(14);
		}
	}

	// Types
	&--COLOR {
		border-color: lighten($primary, 28%) !important;
		background-color: lighten($primary, 47%) !important;

		.fileDropzone-icon {
			color: $primary;
		}
	}

	&--COLOR, &--BLACK, &--AUTO {
		min-height: 130px;
	}

	&--COLOR, &--BLACK {
		.fileDropzone-placeholder {
			font-size: px2rem(14);
		}
	}
}
