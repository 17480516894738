@mixin careerBackground($image) {
	position: relative;

	> .container {
		position: relative;
	}
	
	&:before {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background-image: url('#{$image}');
		background-position: center center;
		background-repeat: no-repeat;
		content: "";
		@content;
	}
}


@mixin faIcon($icon) {
	content: fa-content($icon);
	@include fontFamily("fa");
	display: inline-block;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	@content;
}
