// **
// Title H1
// **
@mixin h1 {
	letter-spacing: $letter-spacing;
	.ff-extra.is-larger {
		font-size: fontRound($h1-font-size * 1.45);
		position: relative;
		white-space: nowrap;

		&:after {
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			position: absolute;
			content: $icon-line;
			color: $primary;
			@include fontFamily("ico");
			@include font(px2rem(11), 1);
		}
	}

	@include media-breakpoint-down(lg) {
		font-size: fontRound($h1-font-size * .85);
		.ff-extra.is-larger {
			font-size: fontRound($h1-font-size * 1.1);
		}
	}

	@include media-breakpoint-down(sm) {
		font-size: fontRound($h1-font-size * .7);
		.ff-extra.is-larger {
			font-size: fontRound($h1-font-size * 1);
		}
		br {
			display: none;
		}
	}

	@include media-breakpoint-down(xs) {
		font-size: fontRound($h1-font-size * .6);
		.ff-extra.is-larger {
			font-size: fontRound($h1-font-size * .9);
		}
	}
}

.h1 {
	@include h1;
}

// **
// Title H2
// **
@mixin h2 {
	letter-spacing: $letter-spacing;
	@include media-breakpoint-down(md) {
		font-size: fontRound($h2-font-size * .9);
	}

	@include media-breakpoint-down(sm) {
		font-size: fontRound($h2-font-size * .8);
		br {
			display: none;
		}
	}

}

.h2 {
	@include h2;
}

// **
// Title H3
// **
@mixin h3 {
	letter-spacing: $letter-spacing;
	@include media-breakpoint-down(md) {
		font-size: fontRound($h3-font-size * .9);
	}
	@include media-breakpoint-down(sm) {
		br {
			display: none;
		}
	}
}

.h3 {
	@include h3;
}

// **
// Title H4
// **
@mixin h4 {
	letter-spacing: $letter-spacing;
	@include media-breakpoint-down(sm) {
		br {
			display: none;
		}
	}
}

.h4 {
	@include h4;
}

// **
// Title H5
// **
@mixin h5 {
	letter-spacing: $letter-spacing;
	font-weight: $font-weight-bold;
}

.h5 {
	@include h5;
}

// **
// Title H4
// **
@mixin h6 {
	letter-spacing: $letter-spacing;
}

.h6 {
	@include h6;
}


// **
// Font weight as a class
// **
@each $size, $value in $font-weight-styles {
	.fw-#{$size} {
		font-weight: $value !important;
	}
}

// **
// Font size
// **
@each $size, $value in $font-size-styles {
	.fs-#{$size} {
		font-size: nth($value, 1) !important;
		line-height: nth($value, 2) !important;
	}
}

.fs-xl {
	@include media-breakpoint-down(sm) {
		font-size: fontRound($font-size-xl * .9) !important;
	}
}

// **
// Font family
// **
@each $type, $value in $fontList {
	.ff-#{$type} {
		font-family: $value;
	}
}

.title-decorated {
	$size: 40px;
	$size-padding: $size + 12;
	@include media-breakpoint-up(md) {
		> span {
			display: inline-block;
			padding-left: $size-padding;
			padding-right: $size-padding;
			position: relative;
			&:before, &:after {
				position: absolute;
				top: 50%;
				height: 2px;
				background-color: $primary;
				content: "";
				display: block;
				width: $size;
				transform: translateY(-1px);
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
	}
}

.title-decorated-bottom {
	$size: 40px;
	position: relative;
	padding-bottom: spacer(2);
	
	&:before, &:after {
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 2px;
		background-color: $primary;
		content: "";
		display: block;
		width: $size;
		transform: translateX(-50%);
	}
}

// **
// Row sizes
// **
@each $size, $value in $grid-gutters {
	$s: $value / 2;
	.row-#{$size} {
		margin-left: -$s;
		margin-right: -$s;

		> [class^="col"], > [class*=" col"] {
			padding-left: $s;
			padding-right: $s;
		}
	}
}


// **
// Multi Row
// **
.multi-row {
	> [class^="col"], > [class*=" col"] {
		padding-top: $grid-gutter-spacer;
		padding-bottom: $grid-gutter-spacer;
	}

	@each $size, $value in $grid-gutters {
		$s: $value / 2;
		&.row-#{$size} {
			> [class^="col"], > [class*=" col"] {
				padding-top: $s;
				padding-bottom: $s;
			}
		}
	}
}

/**
 * Border width
 */
.border-2 {
	border-width: 2px !important;
}
