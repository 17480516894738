@import "../../../../../node_modules/slick-slider/slick/slick.scss";

// Hide slide before init except first one
.slick-preInit {
	&:not(.slick-initialized) {
		.slide:not(:first-child) {
			display: none !important;
		}
	}
}

// Arrows
.slick-arrow {
	position: absolute;
	z-index: 20;
	top: 50%;
	color: $white;
	@include font(px2rem(18), 1);
	display: flex;
	justify-content: center;
	align-items: center;
	background: $black;
	width: 42px;
	height: 42px;
	border-width: 0;
	padding: 0;
	margin: 0;
	outline: 0 !important;
	box-shadow: none;
	border-radius: 100%;

	&:hover {
		background-color: $primary;
	}

	&.prev {
		left: 0;
		transform: translate(-50%, -50%);
	}

	&.next {
		right: 0;
		transform: translate(50%, -50%);
	}
}

.slick-dots {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	li {
		padding: 5px;
	}
	button {
		display: block;
		width: 10px;
		height: 10px;
		background-color: $secondary;
		border-radius: 100%;
		cursor: pointer;
		overflow: hidden;
		@include font(px2rem(0), 1);
		text-indent: 10em;
		border-width: 0;
		padding: 0;
		margin: 0;
	}
	button:hover, .slick-active button {
		background-color: $primary;
	}
}
